<template>
  <v-row>
    <v-col cols="3">
      <v-menu
        v-model="menuStartDate"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            outlined
            flat
            dense
            background-color="white"
            v-model="params.startDate"
            append-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="params.startDate" @input="menuStartDate = false"></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="3">
      <v-menu
        v-model="menuEndDate"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            outlined
            flat
            dense
            background-color="white"
            v-model="params.endDate"
            append-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="params.endDate" @input="menuEndDate = false"></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="3">
      <v-btn color="success" @click="excel">
        <v-icon>
          mdi-file-excel
        </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import fileDownload from "js-file-download";

export default {
  name: "report-payment",
  data() {
    return {
      menuStartDate: false,
      menuEndDate: false,
      params: {
        startDate: moment()
          .startOf("year")
          .format("yyyy-MM-DD"),
        endDate: moment()
          .endOf("year")
          .format("yyyy-MM-DD"),
      },
    };
  },
  methods: {
    async excel() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/payment", this.params)
        .then(response => {
          fileDownload(
            response.data,
            `report-payment-${this.params.startDate}-${this.params.endDate}.xlsx`
          );
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
};
</script>

<style></style>
